import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { AssetCacheService } from 'src/app/services/asset-cache.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { AssetComponent } from 'src/app/utils/asset-component.template';
import { Attachment } from 'src/dto/dto';

@Component({
    selector: 'app-note-history-dialog',
    templateUrl: './note-history-dialog.component.html',
    styleUrl: './note-history-dialog.component.scss',
    imports: [
        MatInputModule,
        MatButtonModule,
        DatePipe
    ],
    standalone: true,
})
export class NoteHistoryDialogComponent extends AssetComponent {

    note: Attachment = {} as any;

    history: {
        time: number,
        owner: string,
        name: string,
        value: string
    }[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        private readonly assetcache: AssetCacheService,
        private readonly toaster: ToasterService,
        @Inject(MAT_DIALOG_DATA) private readonly data: any = {},
        private readonly user: UserService
    ) {
        super();


        this.asset = data.asset;
        this.note = data.note ?? {};
    }

    override ngOnInit() {
        if (!this.asset) {
            return;
        }

        try {
            this.history = JSON.parse(this.note.data);
        } catch(ex) { console.error(ex) }
    }
}
