import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { StackEditorComponent } from 'ngx-stackedit';
import { AssetCacheService } from 'src/app/services/asset-cache.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { AssetComponent } from 'src/app/utils/asset-component.template';
import { Attachment, AttachmentAPI, AttachmentType, DTO, Note } from 'src/dto/dto';
import { ulid } from 'ulidx';

@Component({
    selector: 'app-note-edit-dialog',
    templateUrl: './note-edit-dialog.component.html',
    styleUrl: './note-edit-dialog.component.scss',
    imports: [
        MatInputModule,
        MatButtonModule,
        StackEditorComponent
    ],
    standalone: true,
})
export class NoteEditDialogComponent extends AssetComponent {

    note: Attachment = {} as any;
    noteHasKnownId = true;

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        private readonly assetcache: AssetCacheService,
        private readonly toaster: ToasterService,
        @Inject(MAT_DIALOG_DATA) private readonly data: any = {},
        private readonly user: UserService
    ) {
        super();


        this.asset = data.asset;
        this.note = structuredClone(data.note ?? {});
    }

    override ngOnInit() {
        if (!this.asset) {
            return;
        }
        if (typeof this.asset.description != "string") {
            this.assetcache.getAsset(this.asset.dto + '.' + this.asset.id).then(asset => {
                if (!asset) {
                    this.toaster.warn("Could not find asset", "Cannot create contribution notes because the asset wasn't resolved")
                    return;
                }

                this.asset = asset;
                this.ngOnInit();
            });
            return;
        }

        // If the dialog is opened without a provided note, we're in create mode.
        if (!this.note.id) {
            let id = ulid();
            this.note = {
                id: -1,
                idx: id,
                // title: "",
                name: "",
                dto: "Attachment",
                description: "",
                parentId: this.asset.id,
                icon: "",
                data: "",
                source: this.asset.id,
                sourceType: this.getAssetReferenceType(),
                target: this.asset.id,
                targetType: this.getAssetReferenceType(),
                type: AttachmentType.CONTRIBUTION
            };
        }
    }

    async save() {
        const historyObj = {
            owner: this.user.email,
            time: Date.now(),
            name: this.note.name,
            value: this.note.description
        };

        if (this.note.id == -1) {
            this.note.data = JSON.stringify([historyObj]);
            this.note.id = (await DTO.attachmentAPI.create(this.note)).id;
        }
        else {
            let oldHistory = [];

            // Try to read the previous history
            try { oldHistory = JSON.parse(this.note.data) } catch(ex) {}

            // append the new history object
            oldHistory.push(historyObj);

            this.note.data = JSON.stringify(oldHistory);

            DTO.attachmentAPI.update(this.note);
        }

        this.dialogRef.close(this.note);
    }

    checkDescription() {
        // Get all of the contribution IDs out of the description field
        const contributionMarkers: string[] = this.asset.description.match(/!\[contribution\]\([^)]+\)/g) ?? [];
        const contributionIds = contributionMarkers.map(cm => cm.match(/\(([^\)]+)/)[1]);

        this.noteHasKnownId = contributionIds.includes(this.note.description);
    }
}
