<div class="header">Topic History</div>

<div class="content">
    @for(version of history; track version) {
        <div class="row">
            <span>Name: </span><span>{{version.name}}</span>
            <br/>
            <span>Updated By: </span><span>{{version.owner}}</span>
            <br/>
            <span>Date: </span><span>{{version.time | date}}</span>
            <br/>
            <textarea readonly class="content">{{version.value}}</textarea>
            <br/>
        </div>
    }
</div>


<div class="controls">
    <div style="flex: 1"></div>
    <button mat-flat-button (click)="dialogRef.close()">Close</button>
</div>
