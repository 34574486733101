<div style="padding: 12px 24px; margin-bottom: 36px;">
    @if(data.title) {
    <div [innerHTML]="data.title" style="font-weight: bold;"></div>
    }
    <div [innerHTML]="data.message" style="padding: 12px 0; min-height: 68px;"></div>

    <div style="float: right;">
        <button mat-flat-button (click)="closeAction()" color="primary">
            <mat-icon>cancel</mat-icon>
            Close
        </button>
    </div>
</div>
